import React, { createContext, useContext } from "react";
import usePagination from "../hooks/usePagination";

type PaginationContextType = ReturnType<typeof usePagination>;

const PaginationContext = createContext<PaginationContextType | undefined>(
  undefined,
);

interface PaginationProviderProps {
  children: React.ReactNode;
  pageLimit?: number;
}

export const PaginationProvider: React.FC<PaginationProviderProps> = ({
  children,
  pageLimit = 10,
}) => {
  const pagination = usePagination(pageLimit);

  return (
    <PaginationContext.Provider value={pagination}>
      {children}
    </PaginationContext.Provider>
  );
};

export const usePaginationContext = () => {
  const context = useContext(PaginationContext);
  if (!context) {
    throw new Error(
      "usePaginationContext must be used within PaginationProvider",
    );
  }
  return context;
};
