import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define the idle state type
interface IdleState {
  showWarning: boolean;
  countdown: number;
  lastUpdated: number;
}

// Create the initial state
const initialIdleState: IdleState = {
  showWarning: false,
  countdown: 0,
  lastUpdated: Date.now(),
};

// Create the idle slice
const idleTimerSlice = createSlice({
  name: "idle",
  initialState: initialIdleState,
  reducers: {
    setTimerState: (
      state,
      action: PayloadAction<{
        showWarning: boolean;
        countdown: number;
        lastUpdated: number;
      }>,
    ) => {
      state.showWarning = action.payload.showWarning;
      state.countdown = action.payload.countdown;
      state.lastUpdated = action.payload.lastUpdated;
    },
    resetTimerState: (state) => {
      state.showWarning = false;
      state.countdown = 0;
      state.lastUpdated = Date.now();
    },
    decrementCountdown: (state) => {
      state.countdown = Math.max(0, state.countdown - 1);
    },
  },
});

// Export the actions
export const { setTimerState, resetTimerState, decrementCountdown } =
  idleTimerSlice.actions;

// Export the reducer
export default idleTimerSlice.reducer;
