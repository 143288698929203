import React from "react";
import comingsoonbg from "../../assets/misc/comingsoon.png";
import Header from "./Header";

const ComingSoon = () => {
  return (
    <>
      <div className="h-screen overflow-hidden flex flex-col">
        <Header />
        <div
          className="relative flex-1 flex flex-col items-center justify-center text-center bg-cover bg-center"
          style={{ backgroundImage: `url(${comingsoonbg})` }}
        >
          {/* Gradient Overlay */}
          <div className="absolute inset-0 bg-gradient-to-b from-[#3F3C51] to-[#7D5BEDB8] to-72%"></div>

          {/* Content */}
          <div className="relative z-10 w-full px-6">
            <h1 className="text-[80px] font-semibold text-white">
              Coming Soon
            </h1>
            <p className="text-white text-[22px] mt-4 w-[82%]  mx-auto font-semibold">
              Discover and book the perfect venue with whether for events,
              meetings, or special occasions. Venue owners can list and manage
              spaces effortlessly, while individuals and businesses can find the
              ideal spot with just a few clicks. A smarter way to connect, book,
              and host!
            </p>
          </div>
        </div>
        {/* Footer */}
        <footer className="w-full bg-primary-400 text-white text-sm py-2 text-center">
          Copyright © 2025 | All Rights Reserved.
        </footer>
      </div>
    </>
  );
};

export default ComingSoon;
