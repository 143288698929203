import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DiscountCreation } from "../lib/Types/discountTypes";

interface DiscountState {
  discounts: DiscountCreation[];
  selectedCardId: string | null;
  selectedDiscount: DiscountCreation | null;
}

const initialState: DiscountState = {
  discounts: [],
  selectedCardId: null,
  selectedDiscount: null,
};

const discountSlice = createSlice({
  name: "discounts",
  initialState,
  reducers: {
    setSelectedDiscount(state, action: PayloadAction<DiscountCreation | null>) {
      state.selectedDiscount = action.payload;
      state.selectedCardId = action.payload?.location_id || null;
    },
  },
});

export const { setSelectedDiscount } = discountSlice.actions;
export default discountSlice.reducer;
