import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";
import { toast } from "sonner";
import { SerializedError } from "vitest";
import moment from "moment";
import i18n from "../utils/i18n";
import { store } from "../store/store";
import {
  DISCOUNT_TYPE,
  discountOnoption,
  PRICINGSCHEMES,
  promotionOnoption,
} from "../data/Constants";
import { updateBookingDetails } from "../store/VenueBookingSlice";
import { Availability, NearByLocation } from "../lib/Types/SearchVenueTypes";
import {
  Food,
  Menu,
  PricingScheme,
  SelectedFoodAndDrink,
  VenueLocation,
} from "../lib/Types/bookingTypes";
import { parsePhoneNumber } from "libphonenumber-js";
import { DiscountCreation } from "../lib/Types/discountTypes";
import { Promotion } from "../lib/Types/promotionTypes";
import { MARGIN_TYPE } from "../lib/enums/Status";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const handleError = (
  error:
    | { status?: number; data?: unknown; message?: string }
    | SerializedError,
) => {
  if (isSerializedError(error)) {
    // Handle SerializedError if necessary
    toast.error(error.message || "An unknown error occurred.");
  } else {
    // Handle custom error shape
    const errorMessage = getErrorMessage(error.data);
    toast.error(errorMessage || "An unknown error occurred.");
  }
};

// Helper function to safely extract message from error.data
const getErrorMessage = (data: unknown): string | undefined => {
  if (typeof data === "object" && data !== null && "message" in data) {
    return (data as { message?: string }).message;
  }
  return undefined;
};

function isSerializedError<T = unknown>(error: T) {
  return error && typeof error === "object" && "message" in error;
}

export function generateOptions(
  data: { id: string | number; [key: `name_${string}`]: string }[],
  selectedLang: string,
) {
  if (data.length)
    return (
      data?.map((ele) => ({
        value: ele?.id,
        label: ele?.[`name_${selectedLang}`],
      })) || []
    );
  else return [];
}

export function generateCityOptions(
  data: { id: string | number; [key: `city_${string}`]: string }[],
  selectedLang: string,
) {
  if (data.length)
    return (
      data?.map((ele) => ({
        value: ele?.[`city_${selectedLang}`],
        label: ele?.[`city_${selectedLang}`],
      })) || []
    );
  else return [];
}

export const formatTime = (time: string): string => {
  return moment(time, "HH:mm").local().format("hh:mm A");
};

export const formatRating = (avg: number, count: number) => {
  if (count === 0) {
    return 0;
  }
  return parseFloat(avg.toFixed(2));
};

// Helper to convert time to hours
export const timeToHours = (time: string) => {
  const [hours, minutes] = time.split(":").map(Number);
  return hours + minutes / 60;
};

export const tomorrow = () => {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  tomorrow.setHours(0, 0, 0, 0);
  return tomorrow;
};

export const today = () => {
  const today = new Date();
  today.setDate(today.getDate());
  today.setHours(0, 0, 0, 0);
  return today;
};

export const convertToISOString = (date: string) => {
  const formattedDate = new Date(date);
  formattedDate.setDate(formattedDate.getDate());
  formattedDate.setHours(0, 0, 0, 0);
  return formattedDate;
};

// Function to calculate the difference in hours
// export const calculateHoursDifference = (
//   startTime: string | undefined,
//   endTime: string | undefined
// ): number => {
//   const format = "HH:mm:ss"; // Define the time format

//   const start = moment(startTime, format);
//   const end = moment(endTime, format);

//   // Calculate the difference in hours
//   const differenceInHours = end.diff(start, "hours", true); // 'true' returns float hours

//   return differenceInHours;
// };

export const calculateHoursDifference = (
  startTime: string | undefined,
  endTime: string | undefined,
): number => {
  const format = "HH:mm:ss"; // Define the time format

  const start = moment(startTime, format);
  let end = moment(endTime, format);

  // If the end time is 23:59:00, treat it as 00:00:00 of the next day
  if (end.format(format) === "23:59:00") {
    end = moment("00:00:00", format).add(1, "day"); // Set it to 00:00:00 next day
  }

  // Calculate the difference in hours, returning float hours
  const differenceInHours = end.diff(start, "hours", true);

  // If the difference is extremely close to an integer (like 11.983), round it to the next whole hour
  if (differenceInHours % 1 >= 0.95) {
    return Math.ceil(differenceInHours); // Round up if difference is close to the next hour
  }

  return differenceInHours;
};

// export const calculateISOHoursDifference = (
//   startTime: string | undefined,
//   endTime: string | undefined,
// ): number => {
//   const start = moment(startTime);
//   const end = moment(endTime);

//   const differenceInHours = end.diff(start, "hours");

//   return differenceInHours;
// };

export const calculateISOHoursDifference = (
  startTime: string | undefined,
  endTime: string | undefined,
): number => {
  if (!startTime || !endTime) return 0;

  const start = moment(startTime);
  const end = moment(endTime);

  // Calculate the duration between the two times
  const duration = moment.duration(end.diff(start));

  // Return the total number of hours, rounded to the nearest integer
  const hours = Math.round(duration.asHours());

  // If it's 23 hours and less than a minute away, we consider it 24 hours
  if (hours === 23 && duration.minutes() >= 59) {
    return 24;
  }

  return hours;
};

export const calculateDaysDifference = (
  startDate: string | number | undefined,
  endDate: string | number | undefined,
): number => {
  // If either startDate or endDate is invalid or undefined, return 0
  if (!startDate || !endDate) {
    return 0;
  }

  const format = "YYYY-MM-DD"; // Define the date format
  const start = moment(startDate, format);
  const end = moment(endDate, format);

  // If either date is invalid, return 0
  if (!start.isValid() || !end.isValid()) {
    return 0;
  }

  // Calculate the difference in days
  const differenceInDays = end.diff(start, "days", true);

  // If the start and end dates are the same, return 1 day
  if (differenceInDays === 0) {
    return 1;
  }

  // If the difference is less than 1, return 1 day
  return Math.ceil(differenceInDays) + 1;
};

export const calculateDaysEnableDifference = (
  startDate: string | number | undefined,
  endDate: string | number | undefined,
  enabledDays?: string[], // Optional enabledDays array
): number => {
  if (!startDate || !endDate) {
    return 0;
  }

  const format = "YYYY-MM-DD";
  const start = moment(startDate, format);
  const end = moment(endDate, format);

  if (!start.isValid() || !end.isValid()) {
    return 0;
  }

  if (!enabledDays || enabledDays.length === 0) {
    const differenceInDays = end.diff(start, "days", true);
    return differenceInDays === 0 ? 1 : Math.ceil(differenceInDays) + 1;
  }

  let count = 0;
  let foundFirstEnabled = false; // Track if we've found the first enabled day
  const current = start.clone();

  while (current.isSameOrBefore(end)) {
    const dayOfWeek = current.isoWeekday().toString();
    const isEnabled = enabledDays.includes(dayOfWeek);

    // If it's an enabled day OR it's the first valid day within the range
    if (isEnabled || !foundFirstEnabled) {
      count++;
      foundFirstEnabled = true;
    }

    current.add(1, "days");
  }
  return count;
};

//
export const formatDate = (
  date: string | number | Date,
  format: string = "hh:mm a",
) => {
  return moment.utc(date).local().format(format);
};

// InstantBooking Timing display
export const formatBookingDate = (dateTime: string) => {
  const date = new Date(dateTime);
  const optionsDate: Intl.DateTimeFormatOptions = {
    month: "short",
    day: "numeric",
  };
  const optionsTime: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  const formattedDate = date.toLocaleString("en-US", optionsDate);
  const formattedTime = date
    .toLocaleString("en-US", optionsTime)
    .replace(/\s?(AM|PM)$/, "$1"); // Remove space before AM/PM

  return { formattedDate, formattedTime };
};

// Add Commas in the Currency
export const formatCurrency = (value: number) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: i18n.t("USD"),
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
};
//
export const getCurrencySymbol = () => {
  const currentLanguage = i18n.language;

  const currency = store.getState().auth.preferences.currency;

  if (currency == "SAR") {
    return currentLanguage === "ar" ? "﷼" : "SAR";
  }

  if (currency == "USD") {
    return currentLanguage === "ar" ? "$" : "USD";
  }

  return currency;
};

// Formate Rating Date
export const formatRatingDate = (dateString: string): string => {
  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "long",
    year: "numeric",
  };

  const date = new Date(dateString);
  return date.toLocaleDateString("en-GB", options);
};

export function reverseCheck<T extends Record<string, number>>(
  options: T,
  value: number,
): keyof T | null {
  const reverseMap = Object.fromEntries(
    Object.entries(options).map(([key, val]) => [val, key]),
  ) as Record<number, keyof T>;

  return reverseMap[value] || null; // Return the key or null if not found
}

export const calculateMidHour = (initialStart: string, initialEnd: string) => {
  const startHour = timeToHours(initialStart);
  const endHour = timeToHours(initialEnd);
  const midpointHour = (startHour + endHour) / 2;
  const midpointHourFormatted = moment()
    .startOf("day")
    .add(midpointHour, "hours")
    .format("HH:mm:ss");
  return midpointHourFormatted;
};

export const convertDateTimeToUTC = (date: string, time: string) => {
  const utcDateTime = moment(`${date} ${time}`).utc().format();
  return utcDateTime;
};

export const getCurrentTime = () => {
  return moment().format("HH:mm:ss");
};

// calculate Discount
export const calculateDiscountedPrice = (
  price: number,
  discountType: number,
  discountValue: number,
): number => {
  let discountedPrice = price;
  if (discountType === DISCOUNT_TYPE.PERCENTAGE) {
    discountedPrice = price - (price * discountValue) / 100;
  } else if (discountType === DISCOUNT_TYPE.FIXED_AMOUNT) {
    discountedPrice = price - discountValue;
  }

  return discountedPrice < 0 ? 0 : discountedPrice;
};

export const getCurrentHourPlusOne = (): string => {
  const now = new Date();

  // Create a new Date object for the next hour
  const nextHour = new Date(now.getTime());
  nextHour.setHours(now.getHours() + 1, 0, 0, 0); // Add 1 hour and reset minutes, seconds, and milliseconds

  // Format the time as HH:mm:ss
  const hours = String(nextHour.getHours()).padStart(2, "0");
  const minutes = String(nextHour.getMinutes()).padStart(2, "0");
  const seconds = String(nextHour.getSeconds()).padStart(2, "0");
  return `${hours}:${minutes}:${seconds}`;
};

export const areKeysEmpty = (
  ds: Record<string, string | number | undefined> | string[],
): boolean => {
  if (Array.isArray(ds)) {
    return ds.every((value) => !value);
  } else if (typeof ds === "object" && ds !== null) {
    return Object.values(ds).every((value) => !value);
  }
  // You could return false or throw an error if the type doesn't match
  return false; // Or handle this case as needed
};

export const checkIfCurrentDate = (date: string): boolean => {
  return date === moment().format("YYYY-MM-DD");
};

export const isEndTimeAfterCurrentHourPlusOne = ({
  endTime,
}: {
  endTime: string;
}) => {
  const formattedEndTime = convertTimeToNumber(endTime);
  const currentHourPlusOne = convertTimeToNumber(getCurrentHourPlusOne());
  if (formattedEndTime - currentHourPlusOne > 0) return true;
  else return false;
};

export function isBeforeCurrentDateTime(utcTime: string) {
  const givenTime = moment.utc(utcTime);
  const currentTime = moment.utc();

  return givenTime.isAfter(currentTime);
}

export function isCurrentTimeBeforeOrBetwnSlot(
  startDate: string,
  startTime: string,
  endTime: string,
): boolean {
  // Get the current date and time
  const currentTime = moment();
  const currentDate = moment().startOf("day"); // Today's date without time

  // Parse the start date
  const startDateMoment = moment(startDate, "YYYY-MM-DD");

  // If the start date is in the future, return false
  if (startDateMoment.isAfter(currentDate)) {
    return false;
  }

  // If the start date is today, check the time slot
  if (startDateMoment.isSame(currentDate, "day")) {
    // Parse start and end times for today's date
    const start = moment(`${startDate} ${startTime}`, "YYYY-MM-DD HH:mm");
    const end = moment(`${startDate} ${endTime}`, "YYYY-MM-DD HH:mm");

    // Handle crossing midnight for the end time
    if (end.isBefore(start)) {
      end.add(1, "day");
    }

    // Return true if current time is before the start time
    if (currentTime.isBefore(start)) {
      return false;
    }

    // Return true if current time is within the slot
    if (currentTime.isBetween(start, end, null, "[)")) {
      return true;
    }

    // Return true if current time is after the end time
    if (currentTime.isAfter(end)) {
      return true;
    }
  }

  // For past dates, return false
  return false;
}

export const convertTimeToNumber = (time: string): number => {
  const [hour, minute] = time.split(":").map(Number);
  return hour + minute / 60;
};

export const updateBookingFoodStateInRedux = (data: SelectedFoodAndDrink[]) => {
  store.dispatch(
    updateBookingDetails({
      selectedFoodAndDrink: data,
    }),
  );
};

export const checkClash = (
  availabilities: Availability[],
  values: { startTime: string; endTime: string },
  dateToCheck: string,
  endDateToCheck: string,
): boolean => {
  // Convert values into Moment objects for comparison
  const startTime = moment(`${dateToCheck}T${values.startTime}`);
  const endTime = moment(`${endDateToCheck || dateToCheck}T${values.endTime}`);

  const isClashing = availabilities.some((slot) => {
    const slotStartTime = moment(slot.start_date_time);
    const slotEndTime = moment(slot.end_date_time);

    return (
      startTime.isBetween(slotStartTime, slotEndTime, undefined, "[)") || // overlaps start
      endTime.isBetween(slotStartTime, slotEndTime, undefined, "(]") || // overlaps end
      (startTime.isSameOrBefore(slotStartTime) &&
        endTime.isSameOrAfter(slotEndTime)) // encloses the slot
    );
  });

  return isClashing;
};

export function checkIfStartDateIsBeforeEndDate(
  startDate: string,
  endDate: string,
) {
  const start = moment(startDate, "YYYY-MM-DD");
  const end = moment(endDate, "YYYY-MM-DD");
  return start.isSameOrBefore(end);
}

export function calculateBookingStartDate(
  values: PricingScheme,
  packageId?: string,
) {
  if (packageId && values?.packageStartDate) {
    const packageStartDate = new Date(values?.packageStartDate);
    packageStartDate.setHours(0, 0, 0, 0);

    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    return currentDate >= packageStartDate ? today() : packageStartDate;
  }
  // if the pricingScheme is PER_DAY, allow booking from the next day
  if (values?.pricingScheme === PRICINGSCHEMES.PER_DAY) {
    return tomorrow();
  } else if (
    /** if the pricingScheme is PER_HOUR/PER_HOUR_PER_PERSON/PER_HALF_DAY, then if currentTime + 1 = venueEndTime, allow
     * booking from the next day else allow booking from the current day
     */
    values?.pricingScheme === PRICINGSCHEMES.PER_HOUR ||
    values?.pricingScheme === PRICINGSCHEMES.PER_HOUR_PER_PERSON ||
    values?.pricingScheme === PRICINGSCHEMES.PER_HALF_DAY
  ) {
    return isEndTimeAfterCurrentHourPlusOne({
      endTime: values?.venueEndTime || "",
    })
      ? today()
      : tomorrow();
  }
}

export function calculateBookingEndDate(
  values: PricingScheme,
  packageId?: string,
) {
  if (packageId && values?.packageEndDate) {
    const packageEndDate = new Date(values?.packageEndDate);
    packageEndDate.setDate(packageEndDate.getDate());
    packageEndDate.setHours(23, 59, 0, 0);
    return packageEndDate;
  } else return "";
}

export function calculateInstantBookingStartDate(values: { endTime: string }) {
  return isEndTimeAfterCurrentHourPlusOne({
    endTime: values?.endTime,
  })
    ? today()
    : tomorrow();
}

export function isEmptyState<T extends object>(
  state: T,
  initialState: T,
): boolean {
  return Object.keys(initialState).every((key) => {
    const stateValue = state[key as keyof T];
    const initialValue = initialState[key as keyof T];

    if (Array.isArray(stateValue)) {
      return stateValue.length === 0;
    }

    if (typeof stateValue === "object" && stateValue !== null) {
      return Object.keys(stateValue).length === 0;
    }

    return stateValue === initialValue;
  });
}

export function getFormattedPrice({
  price,
  discountType,
  discountValue,
  isPromotion,
}: {
  price: number;
  discountType: number;
  discountValue: number;
  isPromotion?: boolean;
}) {
  let discountedPrice = 0;

  if (discountType && discountValue) {
    discountedPrice = calculateDiscountedPrice(
      price,
      discountType,
      discountValue,
    );

    if (discountedPrice < 0) {
      discountedPrice = 0;
    }
  }

  return isPromotion
    ? "Free"
    : discountType
      ? discountedPrice > 0
        ? discountedPrice
        : 0
      : "";
}

export const validatePhoneNumber = (phoneNumber: string): boolean => {
  try {
    const parsedNumber = parsePhoneNumber(phoneNumber);
    return parsedNumber?.isPossible() ?? false;
  } catch {
    return false;
  }
};

export const calcLocationAddress = (
  location?: NearByLocation | VenueLocation,
  selectedLanguage?: string,
) => {
  return `${
    location?.[`name_${selectedLanguage}`]
  }, ${location?.[`neighborhood_${selectedLanguage}`]}, ${location?.[`city_${selectedLanguage}`]}, ${location?.[`region_${selectedLanguage}`]}, ${location?.[`country_${selectedLanguage}`]}`;
};

export function generateLabelsAndValues(
  constantsObject: Record<string, string | number>,
): { label: string; value: string | number }[] {
  return Object.keys(constantsObject)
    .filter((key) => isNaN(Number(key)))
    .map((key) => ({
      label: i18n.t(key),
      value: constantsObject[key],
    }));
}

export const formatSeconds = (totalSeconds: number) => {
  const duration = moment.duration(totalSeconds, "seconds");
  const minutes = Math.floor(duration.asMinutes());
  const seconds = duration.seconds();
  return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
};

export const checkIfPast = (startDateTime: string) => {
  return moment().isAfter(moment(startDateTime));
};

export const formattedSelectedMenus = (menus: Menu[]) => {
  return menus?.map((menu) => {
    const venueMenu = menu.venue_menu.menu;
    return {
      menu_id: menu.id, // outer ID
      id: menu?.menu_id, // inner Id
      name: venueMenu.name,
      price: venueMenu.price,
      path: venueMenu.path,
      quantity: menu.quantity,
    };
  });
};

export const formattedSelectedFoodPackages = (packages: Food[]) => {
  return packages?.map((pkg) => {
    const foodPackage = pkg.venue_food_package?.food_package;
    return {
      package_id: pkg.id,
      id: pkg?.food_package_id,
      name: foodPackage?.name,
      price: foodPackage?.price,
      path: foodPackage?.path,
      description: foodPackage?.description || "",
      quantity: pkg.quantity,
    };
  });
};

export const convertToDateTimeUTC = (date: string, time: string) => {
  const formattedDate = moment
    .utc(`${formatDate(String(date), "yyyy-MM-DD")} ${time}`)
    .toISOString();
  return formattedDate;
};

export const isCouponCodeApplied = (
  discountList?: DiscountCreation[] | Promotion[],
  couponCodeToCheck?: string,
): boolean => {
  if (!couponCodeToCheck || !discountList) return false;
  const couponCodeMatches = discountList?.find(
    (discount) => discount?.code === couponCodeToCheck,
  );

  return couponCodeMatches ? true : false;
};

// get discount type name
export const getDiscountName = (
  reportData: DiscountCreation,
  id: number,
  selectedLanguage: "en" | "ar",
): string | null => {
  const discount = discountOnoption.find((option) => option.id === id);
  const discountName = discount
    ? discount[`name_${selectedLanguage}` as "name_en" | "name_ar"]
    : null;

  const lengths = {
    menus: reportData?.menus?.length || 0,
    addons: reportData?.addons?.length || 0,
    food_packages: reportData?.food_packages?.length || 0,
    venues: reportData?.venues?.length || 0,
  };

  const maxCategory = Object.entries(lengths).reduce((a, b) =>
    a[1] > b[1] ? a : b,
  );

  const maxLength = maxCategory[1];

  return maxLength > 0 ? ` ${maxLength} ${discountName}` : discountName;
};

// get discount value for discount report
export const formatDiscountStatus = (
  discountType: number,
  value: number,
  currentLanguage: string,
): string => {
  if (discountType === 1) {
    // If percentage discount
    return `${value} %`;
  }

  if (discountType === 2) {
    // If SAR discount
    const currency = currentLanguage === "ar" ? "﷼" : "SAR";
    return `${currency} ${value}`;
  }

  return `${value}`; // Fallback for other cases, if needed
};

// get promotion on name
export const getPromotionName = (
  reports: Promotion,
  id: number,
  selectedLanguage: "en" | "ar",
): string | null => {
  const promotion = promotionOnoption.find((option) => option.id === id);
  if (!promotion) {
    return null;
  }

  const promotionName =
    promotion[`name_${selectedLanguage}` as "name_en" | "name_ar"];
  const menuCount = reports.menus?.length || 0;
  const packageCount = reports.food_packages?.length || 0;

  if (menuCount > 0) {
    return `${menuCount} ${promotionName}`;
  }
  if (packageCount > 0) {
    return `${packageCount} ${promotionName}`;
  }
  if (reports.hours) {
    return `${reports.hours} ${promotionName}`;
  }

  return promotionName;
};

// extend Content From HTML Format
export const stripHtml = (htmlString: string) => {
  const doc = new DOMParser().parseFromString(htmlString, "text/html");
  return doc.body.textContent || "";
};

export function checkMarginValidations(
  type: number,
  margin: number,
  price: string | number,
) {
  if (type === MARGIN_TYPE["COMMON.PERCENTAGE"]) {
    return margin > 0 && margin <= 100;
  } else if (type === MARGIN_TYPE["COMMON.AMOUNT"]) {
    return margin <= Number(price);
  } else {
    return false;
  }
}

export const getMinTime = (values: PricingScheme) => {
  const selectedDate = values?.startdate;
  const currentTime = moment();
  // const packageStartDateUTC = convertDateTimeToUTC(
  //   values?.packageStartDate,
  //   values?.packageEndTime
  // );
  const venueStartDateUTC = convertDateTimeToUTC(
    selectedDate as string,
    values?.venueStartTime as string,
  );

  const selectedDateIsPackageStartDate =
    values?.packageStartDate === selectedDate;
  const selectedDateIsPackageEndDate = values?.packageEndDate === selectedDate;

  if (selectedDateIsPackageStartDate) {
    return moment(currentTime, "HH:mm").isAfter(
      moment(values?.packageStartTime, "HH:mm"),
    )
      ? getCurrentHourPlusOne()
      : values?.packageStartTime;
  }

  if (selectedDateIsPackageEndDate) {
    return currentTime.isAfter(venueStartDateUTC)
      ? getCurrentHourPlusOne()
      : values?.venueStartTime;
  }

  if (!selectedDateIsPackageStartDate && !selectedDateIsPackageEndDate) {
    return moment().isAfter(moment(venueStartDateUTC))
      ? getCurrentHourPlusOne()
      : values?.venueStartTime;
  }
};

export const getMaxTime = (values: PricingScheme) => {
  const { packageEndDate, packageEndTime, venueEndTime, startdate } = values;
  const selectedDate = startdate;
  const isPackageEndDateToday = packageEndDate === selectedDate;

  if (isPackageEndDateToday && packageEndTime && venueEndTime) {
    return packageEndTime;
  }
  return venueEndTime;
};

export const checkIfPackageIsRunning = (packageEndDateTime: string) => {
  // Get current UTC date-time
  const currentUTCDateTime = moment.utc();

  // Convert packageStartDateTime to a moment object
  const packageEndMoment = moment.utc(packageEndDateTime);

  // Check if packageStartMoment is in the past but within the last minute
  if (
    currentUTCDateTime.isSameOrAfter(packageEndMoment) ||
    packageEndMoment.diff(currentUTCDateTime, "minutes") <= 60
  ) {
    return false;
  } else {
    return true;
  }
};
