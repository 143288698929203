import { createSlice } from "@reduxjs/toolkit";
import { Preference, RegisterData, UserResData } from "../lib/Types/authTypes";
import { authService } from "../services/authService";
import { EN } from "../utils/i18n";
import { OTP_TIMER, ROLES, USERS_TYPE } from "../data/Constants";
import { PermissionsType } from "../data/Permissions";

export interface AuthSlice {
  registerUser: (data: RegisterData) => void;
}

interface UserState {
  fcmToken: string | null;
  user: UserResData | null;
  verifyUserEmail: boolean;
  email: string | null;
  resetPasswordToken: string | null; // token that will be sent for "Forgot password"
  preferences: Preference;
  userType: number;
  userUpdateProfileImage: File | null;
  roleType: number | undefined;
  resendOTPTimer: number;
  isGuestUser: boolean;
}

const initialState: UserState = {
  user: null,
  fcmToken: null,
  email: null,
  verifyUserEmail: false,
  resendOTPTimer: OTP_TIMER,
  resetPasswordToken: null,
  preferences: {
    language: EN,
    currency: "SAR",
  },
  userType: USERS_TYPE.USER,
  userUpdateProfileImage: null,
  roleType: undefined,
  isGuestUser: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    resetpass: (state, action) => {
      if (state.user) {
        state.user = { ...state.user, token: action.payload };
      }
    },
    logoutUser: (state) => {
      state.user = null;
      state.userUpdateProfileImage = null;
    },
    changeLanguageSelection: (state, action) => {
      state.preferences.language = action.payload;
    },
    setCurrency: (state, action) => {
      state.preferences.currency = action.payload;
    },
    setUserType: (state, action) => {
      state.userType = action.payload; // Set userType when action is dispatched
    },
    setVerifyUserEmail: (state, action) => {
      state.verifyUserEmail = action.payload;
    },
    updateUserProfileData: (state, action) => {
      state.user = { ...state.user, ...action.payload };
    },
    setResendOTPTimer: (state, action) => {
      state.resendOTPTimer = action.payload;
    },
    resetOTPVerification: (state) => {
      state.resendOTPTimer = OTP_TIMER;
      state.email = "";
      state.resetPasswordToken = "";
    },
    setFcmToken: (state, action) => {
      state.fcmToken = action.payload;
    },
    setGuestUserStatus: (state, action) => {
      state.isGuestUser = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        authService.endpoints.login.matchFulfilled,
        (state, action) => {
          const user = action.payload.data as UserResData;
          state.user = user;
          let permissions: string[] = [];

          if (user?.user?.role) {
            const permissions_formatted =
              user?.user.role.role_has_permissions.map(
                (role) => role?.permissions?.unique_key,
              );
            if (
              permissions_formatted !== undefined &&
              permissions_formatted?.length > 0
            ) {
              permissions = permissions_formatted as string[];
            }
          }
          let roleType: number | undefined = undefined;
          if (user.user_type === USERS_TYPE.ADMIN) {
            roleType = ROLES.SUB_ADMIN;
          } else if (user.user_type === USERS_TYPE.CORPORATE_ADMIN) {
            roleType = ROLES.CORPORATE_SUB_ADMIN;
          } else if (user.user_type === USERS_TYPE.VENUE) {
            permissions = [
              PermissionsType.CREATE_DISCOUNT,
              PermissionsType.GET_DISCOUNT,
              PermissionsType.UPDATE_DISCOUNT,
              PermissionsType.DELETE_DISCOUNT,
              PermissionsType.GET_PROMOTION,
              PermissionsType.UPDATE_PROMOTION,
              PermissionsType.DELETE_PROMOTION,
              PermissionsType.CREATE_PROMOTION,
              PermissionsType.CREATE_VENUE_TABLESETUP, // static
              PermissionsType.CREATE_VENUE_FACILITY, // static
              PermissionsType.CREATE_VENUE_USABILITY, // static
              PermissionsType.DISCOUNT_REPORT,
              PermissionsType.PROMOTION_REPORT,
              PermissionsType.BOOKING_REPORT,
              PermissionsType.GET_VENUEDETAIL_ACCESSS,
            ];
            roleType = ROLES.VENUE_SUB_ADMIN;
          }
          state.user = {
            ...state.user,
            permissions: [...permissions],
          };
          state.roleType = roleType;
        },
      )
      .addMatcher(
        authService.endpoints.verifyOtp.matchFulfilled,
        (state, action) => {
          const data = action.payload.data as { token: string };
          state.resetPasswordToken = data.token;
        },
      );
  },
});

// Export the authentication actions and reducer
export const {
  setUserType,
  setEmail,
  resetpass,
  logoutUser,
  changeLanguageSelection,
  setVerifyUserEmail,
  updateUserProfileData,
  setCurrency,
  setResendOTPTimer,
  resetOTPVerification,
  setFcmToken,
  setGuestUserStatus,
} = authSlice.actions;

export default authSlice.reducer;
