import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import authReducer from "./authSlice";
import { authService } from "../services/authService";
import { userService } from "../services/userService";
import userManagementReducer from "./tableManagementSlice";
import locationReducer from "./locationSlice";
import venueReducer from "./venueSlice";
import venueBookingReducer from "./VenueBookingSlice";
import venueFilterReducer from "./venueFilterSlice";
import InstantBookingReducer from "./instantBookingSlice";
import myBookingFiltersReducer from "./myBookingFiltersSlice";
import discountReducer from "./discountSlice";
import promotionReducer from "./promotionSlice";
import packageReducer from "./packageslice";
import BulkBookingReducer from "./bulkBookingSlice";
import idleTimerReducer from "./idleTimerSlice";

import baseApi from "../services/RootBaseApi";

// Persist configuration for auth
const authPersistConfig = {
  key: "auth",
  version: 1,
  storage,
  whitelist: [
    "user",
    "userType",
    "preferences",
    "roleType",
    "email",
    "verifyUserEmail",
    "resendOTPTimer",
    "isGuestUser",
  ],
};

const userManagementPersistConfig = {
  key: "userManagement",
  version: 1,
  storage,
  whitelist: ["userData"],
};

const LocationPersistConfig = {
  key: "locations",
  version: 1,
  storage,
  whitelist: ["locations"],
};

const VenueDetailPersistConfig = {
  key: "venues",
  version: 1,
  storage,
  whitelist: ["venues"],
};

const VenueBookingPersistConfig = {
  key: "venueBooking",
  version: 1,
  storage,
  whitelist: [
    "currentStep",
    "bookingdetails",
    "userDetails",
    "bookingId",
    "packageId",
    "bookingDetailsAll",
    "currentSelectedVenueToBook",
    "selectedCardId",
    "showCapacityNotification",
    "stepperValidated",
  ],
};

const InstantBookingSliceConfig = {
  key: "instantBooking",
  version: 1,
  storage,
  whitelist: ["selectedInstantBooking", "selectedInstantBookingDate"],
};

const VenuFilterSlicePersistConfig = {
  key: "venueFilter",
  version: 1,
  storage,
  whitelist: [
    "venueFilter",
    "table_setups",
    "venue_type",
    "rating",
    "facility",
    "price_type",
    "values",
    "minimum_capacity",
    "food_preference",
    "freeCancelation",
    "start_date",
    "end_date",
    "location",
    "location_id",
  ],
};

const myBookingFiltersSlicePersistConfig = {
  key: "myBookingFilters",
  version: 1,
  storage,
  whitelist: ["myBookingType"],
};

const DiscountSlicePersistConfig = {
  key: "discounts",
  version: 1,
  storage,
  whitelist: ["selectedDiscount"],
};

const PromotionSlicePersistConfig = {
  key: "promotions",
  version: 1,
  storage,
  whitelist: ["selectedPromotion"],
};

const PackageSlicePersistConfig = {
  key: "package",
  version: 1,
  storage,
  whitelist: ["selectedPackage"],
};

const BulkBookingSliceConfig = {
  key: "bulkBooking",
  version: 1,
  storage,
  whitelist: ["bookingDetails"],
};

const IdelTimerConfig = {
  key: "idleTimer",
  version: 1,
  storage,
  whitelist: ["countdown", "lastUpdated", "showWarning"],
};

// Auth persisted reducer
const persistedReducer = persistReducer(authPersistConfig, authReducer);

const persistedTableManagementReducer = persistReducer(
  userManagementPersistConfig,
  userManagementReducer,
);

const persistedLocationReducer = persistReducer(
  LocationPersistConfig,
  locationReducer,
);

const persistedVenueReducer = persistReducer(
  VenueDetailPersistConfig,
  venueReducer,
);

const persistedVenueBookingReducer = persistReducer(
  VenueBookingPersistConfig,
  venueBookingReducer,
);

const persistmyBookingFilterReducer = persistReducer(
  myBookingFiltersSlicePersistConfig,
  myBookingFiltersReducer,
);

const persistedvenueFilterReducer = persistReducer(
  VenuFilterSlicePersistConfig,
  venueFilterReducer,
);

const persisteddiscountReducer = persistReducer(
  DiscountSlicePersistConfig,
  discountReducer,
);

const persistedpromotionReducer = persistReducer(
  PromotionSlicePersistConfig,
  promotionReducer,
);

const persistedpackageReducer = persistReducer(
  PackageSlicePersistConfig,
  packageReducer,
);

const persistInstantBookingReducer = persistReducer(
  InstantBookingSliceConfig,
  InstantBookingReducer,
);

const persistBulkBookingReducer = persistReducer(
  BulkBookingSliceConfig,
  BulkBookingReducer,
);

const persistIdleTimerReducer = persistReducer(
  IdelTimerConfig,
  idleTimerReducer,
);
// Persisted reducer
export const store = configureStore({
  reducer: {
    [baseApi.reducerPath]: baseApi.reducer,
    [authService.reducerPath]: authService.reducer,
    [userService.reducerPath]: userService.reducer,
    auth: persistedReducer,
    tableManagement: persistedTableManagementReducer,
    location: persistedLocationReducer,
    venue: persistedVenueReducer,
    venueBooking: persistedVenueBookingReducer,
    instantBooking: persistInstantBookingReducer,
    venueFilter: persistedvenueFilterReducer,
    myBookingFilters: persistmyBookingFilterReducer,
    locationdiscounts: persisteddiscountReducer,
    venuespromotions: persistedpromotionReducer,
    packages: persistedpackageReducer,
    bulkBooking: persistBulkBookingReducer,
    idleTimer: persistIdleTimerReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"],
      },
    })
      .concat(baseApi.middleware)
      .concat(authService.middleware)
      .concat(userService.middleware),
});

// Persistor for redux-persist
export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
