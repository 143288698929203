import { BaseQueryFn } from "@reduxjs/toolkit/query";
import { AxiosRequestConfig, AxiosError, AxiosResponse } from "axios";

import ApiClient from "./ApiClient";
import { RootState } from "../store/store"; // Adjust the import based on your store setup
import { logoutUser } from "../store/authSlice";
import { toast } from "sonner";
import { ErrorResponse } from "../lib/Types/commonTypes";
import { getHMACEncryptedData } from "../utils/hmacUtils";

interface BaseQueryParams<D = unknown, P = unknown> {
  url: string;
  method: AxiosRequestConfig["method"];
  data?: D;
  params?: P;
  headers?: Record<string, string>;
}

const getCurrentUTCTimestamp = () => {
  return Date.now().toString();
};

export const baseQuery: BaseQueryFn<BaseQueryParams, unknown, unknown> = async (
  { url, method, data, params, headers: customHeaders },
  api, // This gives access to `getState`
) => {
  try {
    const state = api.getState() as RootState; // Access Redux state
    const token = state.auth.user?.token; // Get the token from state

    // Generate HMAC Signature
    const timestamp = getCurrentUTCTimestamp();
    const hmacSignature = getHMACEncryptedData(method!, url, timestamp);

    const language = state.auth.preferences.language || "en";

    // Configure headers with the token if it exists
    const result: AxiosResponse = await ApiClient({
      url,
      method,
      data,
      params,
      headers: {
        Authorization: token ? `Bearer ${token}` : "", // Add token to Authorization header
        "x-lang": language,
        "x-signature": hmacSignature,
        "x-timestamp": timestamp,
        ...customHeaders,
      },
    });

    return { data: result.data };
  } catch (axiosError) {
    const err = axiosError as AxiosError;

    if (err?.response?.status === 403) {
      api.dispatch(logoutUser());
      const errorMessage = (err.response?.data as ErrorResponse)?.message;
      toast.error(errorMessage);
    }

    if (err?.response?.status === 401) {
      api.dispatch(logoutUser());
    }

    return {
      error: {
        status: err.response?.status,
        data: err.response?.data || err.message,
      },
    };
  }
};
