import { createSlice } from "@reduxjs/toolkit";
import { CreateMultipleReservation } from "../lib/Types/reservationTypes";

const initialState = {
  bookingDetails: {} as CreateMultipleReservation,
};

// Create slice
const BulkBookingSlice = createSlice({
  name: "instantBooking",
  initialState,
  reducers: {
    setBookingDetails: (state, action) => {
      if (action?.payload) state.bookingDetails = action.payload;
      else state.bookingDetails = initialState.bookingDetails;
    },
  },
});

// Export actions and reducer
export const { setBookingDetails } = BulkBookingSlice.actions;

export default BulkBookingSlice.reducer;
