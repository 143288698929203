import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PackageOffer } from "../lib/Types/packageTypes";

interface PackageState {
  selectedPackage: PackageOffer | null;
}

const initialState: PackageState = {
  selectedPackage: null,
};

const packageSlice = createSlice({
  name: "selectedPackage",
  initialState,
  reducers: {
    setSelectedPackage(state, action: PayloadAction<PackageOffer | null>) {
      state.selectedPackage = action.payload;
    },
  },
});

export const { setSelectedPackage } = packageSlice.actions;
export default packageSlice.reducer;
