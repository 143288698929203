import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../api/BaseQuery";

export const KEEP_UNUSED_DATA_FOR = 180;

export const baseApi = createApi({
  reducerPath: "baseApi",
  baseQuery,
  tagTypes: [
    "notifications",
    "Venues",
    "Locations",
    "LocationsDetails",
    "EmailTemplatesList",
    "Role",
    "Permission",
    "BulkBooking",
    "TableSetup",
    "Facilities",
    "Usabilities",
    "GetDiscount",
    "GetPromotions",
    "GetPackageOffers",
    "FoodItems",
    "LocationAddons",
    "Packages",
    "Rating",
    "Tax",
    "Reservations",
    "Bookings",
    "GetBooking",
    "InvitedPeopleList",
    "Reservations",
    "GetAdmindashboard",
    "AuditLogs",
    "TermsCondition",
    "faqs",
    "Search-Venues",
    "User-Mangement",
    "Corporate-User-Mangement",
    "ContactLocation",
  ],
  endpoints: () => ({}),
});

export default baseApi;
