// src/store/slices/venueFilterSlice.js

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { isEmptyState } from "../helpers/HelperFunctions";

export interface VenueFilterState {
  table_setups: string[];
  venue_type: string | number;
  rating: string;
  facility: string[];
  price_type: number | string;
  minimum_capacity: number | null;
  food_preference: boolean;
  freeCancelation: boolean;
  min_price: number | null;
  max_price: number | null;
  category: number | null;
  start_date: string | null;
  end_date: string | null;
  location: string;
  location_id: string;
  city_name?: string;
}

export const initialState: VenueFilterState = {
  table_setups: [],
  venue_type: "",
  rating: "",
  facility: [],
  price_type: "",
  minimum_capacity: null,
  food_preference: false,
  freeCancelation: false,
  min_price: null,
  max_price: null,
  category: null,
  start_date: null,
  end_date: null,
  location: "",
  location_id: "",
  city_name: "",
};

const venueFilterSlice = createSlice({
  name: "venueFilter",
  initialState,
  reducers: {
    setFilterState: (state, action: PayloadAction<VenueFilterState>) => {
      return action.payload;
    },
    clearFilterState: () => {
      return initialState;
    },
  },
});

export const { setFilterState, clearFilterState } = venueFilterSlice.actions;

export const isEmpty = (state: VenueFilterState): boolean => {
  return isEmptyState(state, initialState);
};

export default venueFilterSlice.reducer;
