// locationSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Image {
  id: string;
  path: string;
}

export interface Location {
  id: string;
  name: string;
  city: string;
  country: string;
  images: Image[];
}

interface LocationState {
  locations: Location[];
  selectedLocation: Location | null;
}

const initialState: LocationState = {
  locations: [],
  selectedLocation: null,
};

const locationSlice = createSlice({
  name: "locations",
  initialState,
  reducers: {
    setLocations(state, action: PayloadAction<Location[]>) {
      state.locations = action.payload;
    },
    addLocation(state, action: PayloadAction<Location>) {
      state.locations.push(action.payload);
    },
    updateLocation(state, action: PayloadAction<Location>) {
      const index = state.locations.findIndex(
        (location) => location.id === action.payload.id,
      );
      if (index !== -1) {
        state.locations[index] = action.payload;
      }
    },
    deleteLocation(state, action: PayloadAction<string>) {
      state.locations = state.locations.filter(
        (location) => location.id !== action.payload,
      );
    },
    setSelectedLocation(state, action: PayloadAction<Location | null>) {
      state.selectedLocation = action.payload;
    },
    clearSelectedLocation(state) {
      state.selectedLocation = null;
    },
  },
});

export const {
  setLocations,
  addLocation,
  updateLocation,
  deleteLocation,
  setSelectedLocation,
  clearSelectedLocation,
} = locationSlice.actions;

export default locationSlice.reducer;
