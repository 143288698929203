export enum PermissionsType {
  UPDATE_ROLES = "update_roles",
  DELETE_ROLES = "delete_roles",
  ASSIGN_ROLE = "assign_role",
  GET_ROLE_PERMISSIONS = "get_role_permissions", // -
  UPDATE_ROLE_PERMISSIONS = "update_role_permissions",
  GET_EMAIL_TEMPLATE = "get_email_template", // -
  UPDATE_EMAIL_TEMPLATE = "update_email_template",
  CREATE_INDIVIDUAL_USER = "create_individual_user",
  GET_INDIVIDUAL_USER = "get_individual_user", // -
  UPDATE_INDIVIDUAL_USER_STATUS = "update_individual_user_status",
  UPDATE_INDIVIDUAL_USER_DETAIL = "update_individual_user_detail",
  CREATE_CORPORATE_USER = "create_corporate_user",
  GET_CORPORATE_USER = "get_corporate_user", // -
  UPDATE_CORPORATE_USER_STATUS = "update_corporate_user_status",
  UPDATE_CORPORATE_USER_DETAIL = "update_corporate_user_detail",
  CREATE_VENUE_USER = "create_venue_user",
  GET_VENUE_USER = "get_venue_user", // -
  UPDATE_VENUE_USER_STATUS = "update_venue_user_status",
  UPDATE_VENUE_USER_DETAIL = "update_venue_user_detail",
  CREATE_SUB_ADMIN_USER = "create_sub_admin_user",
  GET_SUB_ADMIN_USER = "get_sub_admin_user", // -
  UPDATE_SUB_ADMIN_USER_STATUS = "update_sub_admin_user_status",
  UPDATE_SUB_ADMIN_USER_DETAIL = "update_sub_admin_user_detail",
  CREATE_TABLE_SETUP = "create_table_setup",
  GET_TABLE_SETUP = "get_table_setup", // -
  UPDATE_TABLE_SETUP = "update_table_setup",
  CREATE_FACILITY = "create_facility",
  GET_FACILITY = "get_facility", // -
  UPDATE_FACILITY = "update_facility",
  CREATE_USABILITY = "create_usability",
  GET_USABILITY = "get_usability", // -
  UPDATE_USABILITY = "update_usability",
  DELETE_USABILITY = "delete_usability",
  CREATE_TAX = "create_tax",
  GET_TAX = "get_tax", // -
  UPDATE_TAX = "update_tax",
  DELETE_TAX = "delete_tax",
  CREATE_PACKAGE = "create_package",
  GET_PACKAGE = "get_package", // -
  UPDATE_PACKAGE = "update_package",
  DELETE_PACKAGE = "delete_package",
  CREATE_PROMOTION = "create_promotion",
  GET_PROMOTION = "get_promotion", // -
  UPDATE_PROMOTION = "update_promotion",
  DELETE_PROMOTION = "delete_promotion",
  CREATE_DISCOUNT = "create_discount",
  GET_DISCOUNT = "get_discount", // -
  UPDATE_DISCOUNT = "update_discount",
  DELETE_DISCOUNT = "delete_discount",

  // static permissions
  CREATE_VENUE_TABLESETUP = "create_venue_table_setup",
  CREATE_VENUE_FACILITY = "create_venue_facilities",
  CREATE_VENUE_USABILITY = "create_venue_usability",
  BOOKING_REPORT = "booking_report",
  PACKAGE_REPORT = "package_report",
  DISCOUNT_REPORT = "discount_report",
  PROMOTION_REPORT = "promotion_report",
  GET_VENUEDETAIL_ACCESSS = "get_venuedetails",
}
