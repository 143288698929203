import React from "react";

import styles from "./style.module.css";
import { cn } from "../../helpers/HelperFunctions";

type LoaderProps = {
  variant: "primary" | "secondary";
  className?: string;
};

const Loader: React.FC<LoaderProps> = ({ variant, className }) => {
  return (
    <div
      className="py-10 flex justify-center items-center"
      data-testid="loader"
    >
      <div className={cn(variant === "primary" && "py-10")}>
        <div
          className={cn(`${styles.loader} ${styles[variant]}`, className)}
          role="presentation"
        />
      </div>
    </div>
  );
};

export default Loader;
