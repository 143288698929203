import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../api/BaseQuery";
import {
  RegisterData,
  LoginData,
  AuthServiceResponse,
  ForgotPassData,
  VerifyOtpData,
  ResendOtpData,
  ResetPasswordData,
  Verifyemail,
} from "../lib/Types/authTypes";

export const authService = createApi({
  reducerPath: "authService",
  baseQuery,
  endpoints: (builder) => ({
    registerUser: builder.mutation<AuthServiceResponse, RegisterData>({
      query: (newUser) => ({
        url: "/auth/signup",
        method: "POST",
        data: newUser,
      }),
    }),
    login: builder.mutation<AuthServiceResponse, LoginData>({
      query: (user) => ({
        url: "/auth/signin",
        method: "POST",
        data: user,
      }),
    }),
    forgotPasswords: builder.mutation<AuthServiceResponse, ForgotPassData>({
      query: (email) => ({
        url: "/auth/forgot-password",
        method: "POST",
        data: email,
      }),
    }),
    verifyOtp: builder.mutation<AuthServiceResponse, VerifyOtpData>({
      query: (otpData) => ({
        url: "/auth/verify-otp",
        method: "POST",
        data: otpData,
      }),
    }),
    resentOtp: builder.mutation<AuthServiceResponse, ResendOtpData>({
      query: (resendotpData) => ({
        url: "/auth/resend-email",
        method: "POST",
        data: resendotpData,
      }),
    }),
    resetPassword: builder.mutation<AuthServiceResponse, ResetPasswordData>({
      query: ({ token, password }) => ({
        url: `/auth/reset-password/${token}`,
        method: "POST",
        data: { password },
      }),
    }),
    verifyemail: builder.mutation<AuthServiceResponse, Verifyemail>({
      query: (verifyData) => ({
        url: "/auth/verify-email",
        method: "POST",
        data: verifyData,
      }),
    }),
  }),
});

export const {
  useRegisterUserMutation,
  useLoginMutation,
  useForgotPasswordsMutation,
  useVerifyOtpMutation,
  useResentOtpMutation,
  useResetPasswordMutation,
  useVerifyemailMutation,
} = authService;
