import apple from "../assets/icons/apple.png";
import outlook from "../assets/icons/outlook.png";
import google from "../assets/icons/google.png";
import { BookingType, CALENDAR } from "../lib/enums/Status";
import user from "../assets/logo/individual_user.svg";
import venue from "../assets/logo/Venue_user.svg";
import corporate from "../assets/logo/Corporate_user.svg";
import upcoming from "../assets/icons/Upcoming.svg";
import pending from "../assets/icons/Pending.svg";
import completed from "../assets/icons/Completed.svg";

export const BASE_URL = "/";
export const MAX_DISPLAY = 6;
export const MAX_ADDON_DISPLAY = 5;
export const MAX_VENUE_DISPLAY = 2;
export const OTP_TIMER = 120;
export const OTP_PAGE = "otp";
export const RESET_PASS_PAGE = "resetPass";
export const LANG_EN = " (EN)";
export const LANG_AR = " (AR)";

// Type of users start
export const USERS_TYPE = {
  ADMIN: 1,
  VENUE: 2,
  CORPORATE_ADMIN: 3,
  CORPORATE_USER: 4,
  USER: 5,
  SUB_ADMIN: 6,
};

export const ROLES = {
  SUB_ADMIN: 1,
  CORPORATE_SUB_ADMIN: 2,
  VENUE_SUB_ADMIN: 3,
};

export const PRICINGSCHEMES = {
  PER_HOUR: 1,
  PER_HALF_DAY: 2,
  PER_DAY: 3,
  PER_HOUR_PER_PERSON: 4,
};

export const pricingSchemeTypes = {
  perHour: 1,
  perHalfDay: 2,
  perDay: 3,
  perHourPerPerson: 4,
};

export const HALF_DAYS_TYPES = {
  FIRST_HALF: 1,
  SECOND_HALF: 2,
};

export const CURRENCY = {
  SAR: "﷼",
  USD: "$",
};

export const OTP_VERIFICATION_TYPE = {
  Email_verification: 1,
  Forgot_password: 2,
};

export const categoryOption = [
  { label_en: "1 star ⭐", label_ar: "1 نجمة ⭐", value: 1 },
  { label_en: "2 star ⭐⭐", label_ar: "2 نجمة ⭐⭐", value: 2 },
  { label_en: "3 star ⭐⭐⭐", label_ar: "3 نجمة ⭐⭐⭐", value: 3 },
  { label_en: "4 star ⭐⭐⭐⭐", label_ar: "4 نجمة ⭐⭐⭐⭐", value: 4 },
  { label_en: "5 star ⭐⭐⭐⭐⭐", label_ar: "5 نجمة ⭐⭐⭐⭐⭐", value: 5 },
  {
    label_en: "6 star ⭐⭐⭐⭐⭐⭐",
    label_ar: "6 نجمة ⭐⭐⭐⭐⭐⭐",
    value: 6,
  },
  {
    label_en: "7 star ⭐⭐⭐⭐⭐⭐⭐",
    label_ar: "7 نجمة ⭐⭐⭐⭐⭐⭐⭐",
    value: 7,
  },
];

export const halls = [
  { name_en: "Conference Hall", name_ar: "قاعة المؤتمرات", id: 1 },
  { name_en: "Banquet Hall", name_ar: "قاعة الولائم", id: 2 },
  { name_en: "Exhibition Hall", name_ar: "قاعة المعارض", id: 3 },
  { name_en: "Auditorium", name_ar: "القاعة الكبرى", id: 4 },
  { name_en: "Ballroom", name_ar: "قاعة الرقص", id: 5 },
  { name_en: "Convention Center", name_ar: "مركز المؤتمرات", id: 6 },
  { name_en: "Wedding Hall", name_ar: "قاعة الزفاف", id: 7 },
  { name_en: "Seminar Room", name_ar: "غرفة الندوات", id: 8 },
  { name_en: "Event Space", name_ar: "مساحة الفعاليات", id: 9 },
  { name_en: "Outdoor Pavilion", name_ar: "الجناح الخارجي", id: 10 },
  { name_en: "Hotel Function Room", name_ar: "غرفة وظائف الفندق", id: 11 },
  { name_en: "Sports Arena", name_ar: "الحلبة الرياضية", id: 12 },
  { name_en: "Cultural Center", name_ar: "المركز الثقافي", id: 13 },
  { name_en: "Training Room", name_ar: "غرفة التدريب", id: 14 },
  { name_en: "Theater Hall", name_ar: "قاعة المسرح", id: 15 },
  { name_en: "Lecture Hall", name_ar: "قاعة المحاضرات", id: 16 },
];

export const userTypes = [
  { name_en: "Admin", name_ar: "المسؤول", id: 1 },
  { name_en: "Venue Owner", name_ar: "المكان", id: 2 },
  { name_en: "Corporate User", name_ar: "مستخدم الشركة", id: 3 },
  { name_en: "Individual User", name_ar: "مستخدم فردي", id: 5 },
  { name_en: "Sub Admin", name_ar: "المسؤول الفرعي", id: 6 },
];

export const notificationTypes = [
  { name_en: "New User", name_ar: "مستخدم جديد", id: 1 },
  { name_en: "Booking", name_ar: "حجز", id: 2 },
  { name_en: "Table Setup", name_ar: "إعداد الطاولة", id: 3 },
  { name_en: "Facility", name_ar: "مرفق", id: 4 },
  { name_en: "Usability", name_ar: "سهولة الاستخدام", id: 5 },
];

// EmailTemplate
export const defaultColors = [
  "#000000",
  "#e60000",
  "#ff9900",
  "#ffff00",
  "#008a00",
  "#0066cc",
  "#9933ff",
  "#ffffff",
  "#facccc",
  "#ffebcc",
  "#ffffcc",
  "#cce8cc",
  "#cce0f5",
  "#ebd6ff",
  "#bbbbbb",
  "#f06666",
  "#ffc266",
  "#ffff66",
  "#66b966",
  "#66a3e0",
  "#c285ff",
  "#888888",
  "#a10000",
  "#b26b00",
  "#b2b200",
  "#006100",
  "#0047b2",
  "#6b24b2",
  "#444444",
  "#5c0000",
  "#663d00",
  "#666600",
  "#003700",
  "#002966",
  "#3d1466",
];

export const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "color",
  "background",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "align",
  "blockquote",
  "code-block",
];

export const carouselResponsiveOptions = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1920 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 1920, min: 1440 },
    items: 3,
  },
  laptop: {
    breakpoint: { max: 1440, min: 1380 },
    items: 3,
  },
  laptop1: {
    breakpoint: { max: 1381, min: 1023 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1,
  },
};

export const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1024 },
    items: 1, // Show one image at a time
  },
  desktop: {
    breakpoint: { max: 1024, min: 768 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 768, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export const discountCarouselResponsive = {
  desktop: {
    breakpoint: { max: 4000, min: 1599 },
    items: 3.5,
  },
  desktop1: {
    breakpoint: { max: 1598, min: 1200 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1398, min: 1200 },
    items: 2.8,
  },
  tablet2: {
    breakpoint: { max: 1199, min: 900 },
    items: 2.3,
  },
  tablet1: {
    breakpoint: { max: 899, min: 600 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 1,
  },
};

export const singleItemCarouselResponsiveOptions = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1024 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 1024, min: 768 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 768, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export const priceTypes = [
  {
    name_en: "Per Hour",
    name_ar: "لكل ساعة",
    id: PRICINGSCHEMES.PER_HOUR,
  },
  {
    name_en: "Per Day / Per Person",
    name_ar: "لكل يوم / لكل شخص",
    id: PRICINGSCHEMES.PER_DAY,
  },
  {
    name_en: "Per Hour / Per Person",
    name_ar: "لكل ساعة / لكل شخص",
    id: PRICINGSCHEMES.PER_HOUR_PER_PERSON,
  },
  {
    name_en: "Per Half Day / Per Person",
    name_ar: "لكل نصف يوم / لكل شخص",
    id: PRICINGSCHEMES.PER_HALF_DAY,
  },
];

export const DISCOUNT_ON = {
  VENUE: 1,
  ADDONS: 2,
  FOODMENU: 3,
  FOODPACKAGE: 4,
};

export const DISCOUNT_TYPE = {
  PERCENTAGE: 1,
  FIXED_AMOUNT: 2,
};

export const PRICE_TYPE = {
  PERCENTAGE: 1,
  FIXED_AMOUNT: 2,
};

export const discountOnoption: {
  [key: `name_${string}`]: string;
  id: number;
}[] = [
  { name_en: "Venue", name_ar: "المكان", id: 1 },
  { name_en: "AddOn(s)", name_ar: "إضافةعلى (ق)", id: 2 },
  { name_en: "Food Menu", name_ar: "قائمة الطعام", id: 3 },
  { name_en: "Food Package", name_ar: "طقم الطعام", id: 4 },
];

export const discountTypeOptions = [
  {
    name_en: "Percentage",
    name_ar: "نسبة مئوية",
    id: DISCOUNT_TYPE.PERCENTAGE,
  },
  {
    name_en: "Fixed Amount",
    name_ar: "مبلغ ثابت",
    id: DISCOUNT_TYPE.FIXED_AMOUNT,
  },
];

export const promotionOnoption: {
  [key: `name_${string}`]: string;
  id: number;
}[] = [
  { name_en: "Free Extra Hours", name_ar: "ساعات مجانية", id: 1 },
  { name_en: "Food Menu", name_ar: "قائمة الطعام", id: 2 },
  { name_en: "Food Package", name_ar: "طقم الطعام", id: 3 },
  { name_en: "Free Half Day", name_ar: "نصف يوم مجاني", id: 4 },
  { name_en: "Free Day", name_ar: "يوم مجاني", id: 5 },
];

export const durationType = [
  { label: "Days", value: "1" },
  { label: "Hours", value: "2" },
  { label: "Half Day", value: "3" },
];

export const PROMOTION_ON = {
  FREEHOURS: 1,
  FOODMENU: 2,
  FOODPACKAGE: 3,
  FREEHALFDAY: 4,
  FREEDAY: 5,
};

export const supportOptions = [
  { name_en: "Payment Support", name_ar: "دعم الدفع", id: 1 },
  { name_en: "Technical Support", name_ar: "الدعم الفني", id: 2 },
  { name_en: "Booking Support", name_ar: "دعم الحجز", id: 3 },
  { name_en: "Cancellation Support", name_ar: "دعم الإلغاء", id: 4 },
  { name_en: "Refund Inquiries", name_ar: "استفسارات الاسترداد", id: 5 },
  { name_en: "Booking Issues", name_ar: "مشاكل الحجز", id: 6 },
  { name_en: "Venue Information", name_ar: "معلومات المكان", id: 7 },
  {
    name_en: "Cancellation Policy Inquiry",
    name_ar: "استفسار سياسة الإلغاء",
    id: 8,
  },
  { name_en: "Invoice Assistance", name_ar: "مساعدة الفاتورة", id: 9 },
  { name_en: "General Inquiries", name_ar: "الاستفسارات العامة", id: 10 },
  {
    name_en: "Feedback and Suggestions",
    name_ar: "التعليقات والاقتراحات",
    id: 11,
  },
  {
    name_en: "Privacy Policy Questions",
    name_ar: "أسئلة سياسة الخصوصية",
    id: 12,
  },
  {
    name_en: "Order Confirmation Issues",
    name_ar: "مشاكل تأكيد الطلب",
    id: 13,
  },
  { name_en: "Error Reporting", name_ar: "الإبلاغ عن الأخطاء", id: 14 },
  { name_en: "Feature Requests", name_ar: "طلبات الميزات", id: 15 },
  { name_en: "Others", name_ar: "أخرى", id: 16 },
];

export const supportEmail = "wethereprojectksa@gmail.com";

export const INVITATION_STATUS = {
  "COMMON.PENDING": 1,
  "COMMON.ACCEPTED": 2,
  "COMMON.REJECTED": 4,
  "COMMON.EXPIRED": 5,
};

export const marginstatusoptions = [
  { name_en: "Pending", name_ar: "قيد الانتظار", id: 1 },
  { name_en: "Completed", name_ar: "مكتمل", id: 2 },
];

export const BookingStatusoptions = [
  { name_en: "Upcoming", name_ar: "قادم", id: 1 },
  { name_en: "Pending", name_ar: "قيد الانتظار", id: 2 },
  { name_en: "Completed", name_ar: "مكتمل", id: 3 },
  { name_en: "Cancelled", name_ar: "تم الإلغاء", id: 4 },
  { name_en: "Rejected", name_ar: "مرفوض", id: 5 },
];

export const CREATE_BOOKING_MODAL = "boooking";
export const INVITED_PEOPLE_MODAL = "invited_people";
export const FILTER_FORM_MODAL = "filter_form";

export const FILTER_FORM_NAME: {
  BOOKING_STATUS: "bookingStatus";
  LOCATION_ID: "locationId";
  VENUE_TYPE: "venueType";
  VENUE_ID: "venueId";
  START_DATE: "startDate";
  END_DATE: "endDate";
  LOCATION_NAME: "locationName";
  VENUE_NAME: "venueName";
  DISCOUNT_ON: "discountOn";
  PROMOTION_ON: "promotionOn";
  USER_TYPE: "userType";
  NOTIFICATION_TYPE: "notificationTypes";
  MARGIN_TYPES: "marginTypes";
} = {
  BOOKING_STATUS: "bookingStatus",
  LOCATION_ID: "locationId",
  VENUE_TYPE: "venueType",
  VENUE_ID: "venueId",
  START_DATE: "startDate",
  END_DATE: "endDate",
  LOCATION_NAME: "locationName",
  VENUE_NAME: "venueName",
  DISCOUNT_ON: "discountOn",
  PROMOTION_ON: "promotionOn",
  USER_TYPE: "userType",
  NOTIFICATION_TYPE: "notificationTypes",
  MARGIN_TYPES: "marginTypes",
};

export const durationTypeOptions: {
  [key: `label_${string}`]: string;
  value: number;
}[] = [
  { label_en: "Hours", label_ar: "ساعات", value: 1 },
  { label_en: "Days", label_ar: "أيام", value: 2 },
  { label_en: "Half Day", label_ar: "نصف يوم", value: 3 },
];

export const timeUnitOptions = [
  { label_en: "Minutes", label_ar: "دقائق", value: 1 },
  { label_en: "Hours", label_ar: "ساعات", value: 2 },
];

export const ADD_TO_CALENDAR: {
  [key: `name_${string}`]: string;
  image: string;
  value: number;
}[] = [
  {
    name_en: "Google",
    name_ar: "Google",
    image: google,
    value: CALENDAR.GOOGLE,
  },
  {
    name_en: "Outlook.com",
    name_ar: "Google",
    image: outlook,
    value: CALENDAR.OUTLOOK,
  },
  {
    name_en: "Apple",
    name_ar: "Apple",
    image: apple,
    value: CALENDAR.APPLE,
  },
];

export const userIcons = {
  5: user,
  2: venue,
  3: corporate,
};

export const BookingIcon = {
  1: upcoming,
  2: pending,
  3: completed,
};

// Admin-Dashboard

export const timeRangeOptions = [
  { label_en: "Week", label_ar: "أسبوع", value: "1" },
  { label_en: "Month", label_ar: "شهر", value: "2" },
  { label_en: "Year", label_ar: "سنة", value: "3" },
];

export const USERS_TYPE_LABELS = {
  2: { label_en: "Venue", label_ar: "المكان" },
  3: { label_en: "Corporate User", label_ar: "مستخدم الشركة" },
  5: { label_en: "Individual User", label_ar: "مستخدم فردي" },
};

export const bookingTypeOptions = [
  {
    label_en: "Upcoming",
    label_ar: "قادم",
    value: BookingType.Upcoming.toString(),
  },
  {
    label_en: "Pending",
    label_ar: "معلق",
    value: BookingType.Pending.toString(),
  },
  {
    label_en: "Completed",
    label_ar: "مكتمل",
    value: BookingType.Completed.toString(),
  },
];

export const WEEKDAYS = [
  { en: "Sun", ar: "الأحد" },
  { en: "Mon", ar: "الاثنين" },
  { en: "Tue", ar: "الثلاثاء" },
  { en: "Wed", ar: "الأربعاء" },
  { en: "Thu", ar: "الخميس" },
  { en: "Fri", ar: "الجمعة" },
  { en: "Sat", ar: "السبت" },
];

export const MONTHS = [
  { en: "Jan", ar: "يناير" },
  { en: "Feb", ar: "فبراير" },
  { en: "Mar", ar: "مارس" },
  { en: "Apr", ar: "أبريل" },
  { en: "May", ar: "مايو" },
  { en: "Jun", ar: "يونيو" },
  { en: "Jul", ar: "يوليو" },
  { en: "Aug", ar: "أغسطس" },
  { en: "Sep", ar: "سبتمبر" },
  { en: "Oct", ar: "أكتوبر" },
  { en: "Nov", ar: "نوفمبر" },
  { en: "Dec", ar: "ديسمبر" },
];

export const VENUE_CONFIGURATIONS_STATUS = {
  APPROVE: 1,
  INACTIVE: 2,
  DELETED: 3,
  PENDING: 4,
  REJECTED: 5,
};

export const GuestUserRoutes = [
  "/dashboard",
  "/dashboard/venuelist",
  "/dashboard/mapview",
  "/venue",
  "/venue/mapview",
  "/package",
  "/support",
  "/",
  "/venue/venuesList",
];

export const MesureUnit = {
  "COMMON.METER": 1,
  "COMMON.FEET": 2,
};
