import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import App from "./App.tsx";
import "./index.css";
import { persistor, store } from "./store/store.ts";
import { LanguageProvider } from "./context/LanguageContext.tsx";
import { LoadScript } from "@react-google-maps/api";
import "react-tooltip/dist/react-tooltip.css";
import Loader from "./components/loader/index.tsx";
import { PaginationProvider } from "./context/PageContext.tsx";
import * as Sentry from "@sentry/react";

if (
  import.meta.env.VITE_APP_NODE_ENV === "development" ||
  import.meta.env.VITE_APP_NODE_ENV === "staging"
) {
  Sentry.init({
    dsn: "https://8f3ac9a91c7dcb716bbdb5fecb7988f8@o4508759778852864.ingest.us.sentry.io/4508917888057344",
    integrations: [
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    environment: import.meta.env.VITE_NODE_ENV,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <LoadScript
      googleMapsApiKey={`${import.meta.env.VITE_APP_GOOGLE_API_KEY}`}
      libraries={["places"]}
      loadingElement={
        <div className="flex justify-center items-center">
          <Loader variant="primary" />
        </div>
      }
    >
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <LanguageProvider>
            <PaginationProvider>
              <App />
            </PaginationProvider>
          </LanguageProvider>
        </PersistGate>
      </Provider>
    </LoadScript>
  </StrictMode>,
);
