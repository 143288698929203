export enum BookingStatus {
  Draft = 1,
  Pending = 2,
  Confirmed = 3,
  Cancelled = 4,
  PaymentPending = 5,
  CheckedIn = 6,
  Completed = 7,
  Rejected = 8,
  SlotReserved = 9,
}

export enum BookingType {
  Upcoming = 1,
  Pending = 2,
  Completed = 3,
  Draft = 4,
}

export enum BulkBookingStatus {
  Pending = 1,
  Accepted = 2,
  Rejected = 3,
  Cancelled = 4,
}

export enum CALENDAR {
  GOOGLE = 1,
  APPLE = 2,
  OUTLOOK = 3,
}

export enum DiscountPromoPrivacy {
  PUBLIC = 1,
  PRIVATE = 2,
}

export enum COUPONCODE_TYPE {
  DISCOUNT = 1,
  PROMOTION = 2,
}

export enum PROMO_DURATION_TYPE {
  HOURS = 1,
  DAYS = 2,
  HALF_DAY = 3,
}

export enum PackageStatus {
  ACTIVE = 1,
  INACTIVE = 2,
  DELETED = 3,
}

export enum STATISTICS_TYPES {
  "COMMON.UPCOMING" = 1,
  "COMMON.RUNNING" = 2,
  "COMMON.COMPLETED" = 3,
}

export enum STATISTICS_BOOKINGS_TYPES {
  "COMMON.UPCOMING" = 1,
  "COMMON.PENDING" = 2,
  "COMMON.COMPLETED" = 3,
}

export enum USER_TYPES {
  "COMMON.INDIVIDUALUSER" = 5,
  "COMMON.CORPORATEUSER" = 3,
  "COMMON.VENUEOWNER" = 2,
}

export enum TERMS_TYPE {
  TERMSCONDITION = 1,
  PRIVACYPOLICY = 2,
}

export const statusOptions = [
  { label: "COMMON.ACTIVE", value: 1 },
  { label: "COMMON.INACTIVE", value: 2 },
];

export enum STATUS {
  ACTIVE = 1,
  INACTIVE = 2,
}

export enum TAX_TYPE {
  AMOUNT = 1,
  PERCENTAGE = 2,
}

export enum MARGIN_TYPE {
  "COMMON.AMOUNT" = 1,
  "COMMON.PERCENTAGE" = 2,
}
