import CryptoJS from "crypto-js";

export const getHMACEncryptedData = (
  method: string,
  url: string,
  timestamp: string,
) => {
  const secretKeyBase64 = import.meta.env.VITE_APP_HMAC_SECRET_KEY;
  if (!secretKeyBase64) {
    throw new Error("HMAC Key is missing in environment variables.");
  }

  const secretKey = CryptoJS.enc.Base64.parse(secretKeyBase64);
  const dataToSign = `${method}|${url}|${timestamp}`;

  const hmac = CryptoJS.HmacSHA256(dataToSign, secretKey);
  return hmac.toString(CryptoJS.enc.Hex);
};
