import { useState, useEffect, useCallback } from "react";
import { useDebounce } from "./useDebounce";

const usePagination = (pageLimit: number = 10) => {
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { handleSearch, debouncedValue, searchTerm } = useDebounce(300);

  useEffect(() => {
    setCurrentPage(1);
  }, [debouncedValue]);

  const goToPage = useCallback(
    (page: number) => {
      if (page >= 1 && page <= totalPages) {
        setCurrentPage(page);
      }
    },
    [totalPages],
  );

  const handleTotalPages = useCallback((pages: number) => {
    setTotalPages(pages);
  }, []);

  return {
    currentPage,
    goToPage,
    pageLimit,
    totalPages,
    handleTotalPages,
    debouncedValue,
    handleSearch,
    searchTerm,
  };
};

export default usePagination;
