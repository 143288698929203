import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface BookingFiltersState {
  selectedLocation: string | null;
  selectedVenue: string | number | null;
  myBookingType: number | null;
  searchTerm: string | null;
  notificationType: number | null;
  marginType: string | null;
}

const initialState: BookingFiltersState = {
  selectedLocation: null,
  selectedVenue: null,
  myBookingType: null,
  searchTerm: "",
  notificationType: null,
  marginType: null,
};

const myBookingFiltersSlice = createSlice({
  name: "myBookingFilters",
  initialState,
  reducers: {
    setSearchTerm: (state, action) => {
      state.searchTerm = action.payload;
    },
    setNotificationType: (state, action) => {
      state.notificationType = action.payload;
    },
    setSelectedLocation: (state, action: PayloadAction<string | null>) => {
      state.selectedLocation = action.payload;
    },
    setSelectedVenue: (state, action: PayloadAction<string | null>) => {
      state.selectedVenue = action.payload;
    },
    setSelectedMyBookingType: (state, action: PayloadAction<number | null>) => {
      state.myBookingType = action.payload;
    },
    clearMyBookingFilters: (state) => {
      state.selectedLocation = null;
      state.selectedVenue = null;
      state.myBookingType = null;
      state.searchTerm = "";
      state.notificationType = null;
      state.marginType = null;
    },
  },
});

export const {
  setSearchTerm,
  setNotificationType,
  setSelectedLocation,
  setSelectedVenue,
  setSelectedMyBookingType,
  clearMyBookingFilters,
} = myBookingFiltersSlice.actions;

export default myBookingFiltersSlice.reducer;
