import React from "react";
import { useLanguage } from "../../context/LanguageContext";
import WethereLogo from "../../assets/logo/Wethere_logo.png";
import WetherearLogo from "../../assets/logo/Wethere_ar_logo.png";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const { selectedLanguage } = useLanguage();
  const navigate = useNavigate();

  return (
    <nav className="bg-primary-10 pt-[25px] pb-[15px]">
      <div className="flex justify-center items-center max-w-screen-xl mx-auto px-4 cursor-pointer">
        {/* Logo */}
        <img
          src={selectedLanguage === "ar" ? WetherearLogo : WethereLogo}
          alt="Wether"
          className={`${selectedLanguage === "ar" ? "h-8 w-44" : "h-8 w-56"}`}
          onClick={() => {
            navigate("/");
          }}
        />
      </div>
    </nav>
  );
};

export default Header;
