import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { UserDetails } from "../lib/Types/bookingTypes";

interface InstantBookingProps {
  selectedInstantBookingDate: string;
  selectedInstantBooking: Partial<UserDetails>;
}
// Initial state
const initialState: InstantBookingProps = {
  selectedInstantBooking: {},
  selectedInstantBookingDate: moment(new Date()).format("YYYY-MM-DD"),
};

// Create slice
const InstantBookingSlice = createSlice({
  name: "instantBooking",
  initialState,
  reducers: {
    setSelectedInstantBooking: (
      state,
      action: PayloadAction<
        | {
            name: string;
            email: string;
            mobile: string;
            totalInvites: number;
            countryCode: string;
            countryShortCode: string;
            startdate: string;
            enddate: string;
            locationId?: string;
            startTime: string;
            endTime: string;
            id: string;
          }
        | undefined
      >,
    ) => {
      if (action.payload) state.selectedInstantBooking = action.payload;
      else state.selectedInstantBooking = initialState.selectedInstantBooking;
    },
    setSelectedInstantBookingDate: (state, action: PayloadAction<string>) => {
      state.selectedInstantBookingDate = action.payload;
    },
  },
});

// Export actions and reducer
export const { setSelectedInstantBooking, setSelectedInstantBookingDate } =
  InstantBookingSlice.actions;

export default InstantBookingSlice.reducer;
