import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { Toaster } from "sonner";

import { RootState } from "../store/store";
import { changeLanguageSelection, setCurrency } from "../store/authSlice";
import i18n, { getDirection } from "../utils/i18n";

interface LanguageContextInterface {
  selectedLanguage: string;
  changeLanguage: (language: string) => void;
}

const LanguageContext = createContext<LanguageContextInterface | undefined>(
  undefined,
);

export const LanguageProvider = ({ children }: { children: ReactNode }) => {
  const selectedLanguage = useSelector(
    (state: RootState) => state.auth.preferences.language,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    changeLanguage(selectedLanguage);
    dispatch(setCurrency("SAR"));
  }, [selectedLanguage]);

  const changeLanguage = useCallback(
    (language: string) => {
      dispatch(changeLanguageSelection(language));
      i18n.changeLanguage(language);
    },
    [dispatch],
  );

  const value = useMemo(() => {
    return {
      selectedLanguage,
      changeLanguage,
    };
  }, [selectedLanguage, changeLanguage]);

  return (
    <LanguageContext.Provider value={value}>
      <div dir={getDirection(selectedLanguage)} data-testid="dir">
        <Toaster
          richColors
          position="top-right"
          duration={2000}
          closeButton={true}
          dir={getDirection(selectedLanguage)}
        />
        {children}
      </div>
    </LanguageContext.Provider>
  );
};

// custom hook to use the language context
export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error("useContext must be used with a LanguageProvider");
  }
  return context;
};
