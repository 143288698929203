import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
} from "react-router-dom";
import "react-multi-carousel/lib/styles.css";

import ComingSoon from "../pages/comingsoon/ComingSoon";

export const AppRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      {/* Default route set to display Coming Soon page */}
      <Route index element={<ComingSoon />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Route>,
  ),
  {
    basename: "/",
  },
);
