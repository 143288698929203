import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Promotion } from "../lib/Types/promotionTypes";

interface PromotionState {
  promotions: Promotion[];
  selectedCardId: string | null;
  selectedPromotion: Promotion | null;
}

const initialState: PromotionState = {
  promotions: [],
  selectedCardId: null,
  selectedPromotion: null,
};

const promotionSlice = createSlice({
  name: "promotions",
  initialState,
  reducers: {
    setSelectedPromotion(state, action: PayloadAction<Promotion | null>) {
      state.selectedPromotion = action.payload;
    },
  },
});

export const { setSelectedPromotion } = promotionSlice.actions;
export default promotionSlice.reducer;
