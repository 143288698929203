import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Venue } from "../lib/Types/venueDetailType";

interface VenueState {
  venues: Venue[];
  selectedVenue: Venue | null;
}

const initialState: VenueState = {
  venues: [],
  selectedVenue: null,
};

const venueSlice = createSlice({
  name: "venues",
  initialState,
  reducers: {
    setVenues: (state, action: PayloadAction<Venue[]>) => {
      state.venues = action.payload;
    },
    addVenue(state, action: PayloadAction<Venue>) {
      state.venues.push(action.payload);
    },
    updateVenue(state, action: PayloadAction<Venue>) {
      const index = state.venues.findIndex(
        (venue) => venue.id === action.payload.id,
      );
      if (index !== -1) {
        state.venues[index] = action.payload;
      }
    },
    deleteVenue(state, action: PayloadAction<string>) {
      state.venues = state.venues.filter(
        (venue) => venue.id !== action.payload,
      );
    },
    setSelectedVenue(state, action: PayloadAction<Venue | null>) {
      state.selectedVenue = action.payload;
    },
    clearSelectedVenue(state) {
      state.selectedVenue = null;
    },
  },
});

export const {
  setVenues,
  addVenue,
  updateVenue,
  deleteVenue,
  setSelectedVenue,
  clearSelectedVenue,
} = venueSlice.actions;

export default venueSlice.reducer;
