import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQuery } from "../api/BaseQuery";
import { Response } from "../lib/Types/commonTypes";
import {
  ChangePasswordData,
  PayloadFormData,
  ProfileData,
  ProfilePicUpdateResponseData,
  ProfileResponseData,
} from "../lib/Types/userTypes";

export const userService = createApi({
  reducerPath: "userService",
  baseQuery,
  endpoints: (builder) => ({
    getProfile: builder.query<ProfileResponseData, void>({
      query: (data) => ({
        url: "/users/profile",
        method: "GET",
        data: data,
      }),
    }),
    updateProfile: builder.mutation<Response, ProfileData>({
      query: (data) => ({
        url: "/users/profile",
        method: "PATCH",
        data: data,
      }),
    }),
    changePassword: builder.mutation<Response, ChangePasswordData>({
      query: (data) => ({
        url: "/users/change-password",
        method: "POST",
        data: data,
      }),
    }),
    updateProfilePicture: builder.mutation<
      ProfilePicUpdateResponseData,
      PayloadFormData
    >({
      query: (data) => ({
        url: "/users/change-profile",
        method: "PATCH",
        data: data,
      }),
    }),
    removeProfilePicture: builder.mutation<Response, void>({
      query: () => ({
        url: "/users/remove-profile",
        method: "DELETE",
      }),
    }),
    deleteAccount: builder.mutation<Response, void>({
      query: () => ({
        url: "/users/delete-account",
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetProfileQuery,
  useChangePasswordMutation,
  useUpdateProfileMutation,
  useUpdateProfilePictureMutation,
  useRemoveProfilePictureMutation,
  useDeleteAccountMutation,
} = userService;
