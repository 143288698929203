import { RouterProvider } from "react-router-dom";
import { AppRouter } from "./routers";

import "./App.css";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    if (!window.tinymce) {
      const script = document.createElement("script");
      script.src = "/tinymce/tinymce.min.js";
      script.referrerPolicy = "origin";
      document.body.appendChild(script);
    }
  }, []);
  return <RouterProvider router={AppRouter} />;
}

export default App;
