import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Venue } from "../lib/Types/SearchVenueTypes";
import {
  Addon,
  BookingDetail,
  SelectedFoodAndDrink,
  Usability,
  UserDetails,
} from "../lib/Types/bookingTypes";
import { TableSetup } from "../lib/Types/tablesetupTypes";
import { DiscountCreation } from "../lib/Types/discountTypes";
import { Promotion } from "../lib/Types/promotionTypes";

// Define VenueState interface
export interface VenueState {
  currentStep: number;
  userDetails: UserDetails;
  currentSelectedVenueToBook?: Venue;
  bookingdetails: {
    selectedUsability: Usability[];
    selectedFoodAndDrink: SelectedFoodAndDrink[];
    selectedTableSetUp: TableSetup;
    selectedAddons: Addon[];
    pricingScheme?: number;
    halfDayScheme?: number | null;
    startTime?: string;
    endTime?: string;
    startDate?: number | string;
    endDate?: number | string;
    startDateTime?: string;
    endDateTime?: string;
    menusCopy?: [];
    foodPackagesCopy?: [];
    addonsCopy?: [];
    status?: number;
    total_invitees: number;
    accepted_invitation: number;
    pending_invitation: number;
    venue: Venue;
  };
  stepperValidated: {
    details: boolean;
    price: boolean;
    usability: boolean;
    food: boolean;
    tableSetup: boolean;
    addOns: boolean;
  };
  bookingDetailsAll: BookingDetail[];
  bookingId: string | null;
  packageId?: string;
  selectedCardId?: string;
  showCapacityNotification: boolean;
}

// Initial state
const initialState = {
  currentSelectedVenueToBook: {} as Venue,
  currentStep: 0,
  userDetails: {
    totalInvites: 0,
    enddate: "",
    startdate: "",
    name: "",
    email: "",
    mobile: "",
    countryCode: "",
    countryShortCode: "",
    title: "",
  },
  bookingdetails: {
    selectedUsability: [] as Usability[],
    selectedFoodAndDrink: [] as SelectedFoodAndDrink[],
    selectedTableSetUp: {} as TableSetup,
    selectedAddons: [],
    pricingScheme: undefined,
    halfDayScheme: undefined,
    startDateTime: "",
    endDateTime: "",
    startTime: "",
    endTime: "",
    startDate: "",
    endDate: "",
    menusCopy: [],
    foodsCopy: [],
    addonsCopy: [],
    status: null,
    couponDetails: {} as DiscountCreation | Promotion,
    accepted_invitation: 0,
    pending_invitation: 0,
    total_invitees: 0,
    venue: {} as Venue,
  },
  stepperValidated: {
    details: true,
    price: false,
    usability: false,
    food: false,
    tableSetup: false,
    addOns: false,
  },
  bookingDetailsAll: [] as BookingDetail[],
  bookingId: "",
  packageId: "",
  selectedCardId: "",
  showCapacityNotification: false,
};

// Create slice
const VenueBookingSlice = createSlice({
  name: "venues",
  initialState,
  reducers: {
    handleCurrentStep: (state, action: PayloadAction<number>) => {
      state.currentStep = action.payload;
    },
    setCurrentSelectedVenueToBook: (state, action) => {
      state.currentSelectedVenueToBook = action.payload;
    },
    setUserDetails: (state, action) => {
      state.userDetails = {
        ...state.userDetails,
        ...action.payload,
      };
    },
    setBookingId: (state, action: PayloadAction<string>) => {
      state.bookingId = action.payload;
    },
    setPackageId: (state, action: PayloadAction<string>) => {
      state.packageId = action.payload;
    },
    setBookingDetails: (state, action) => {
      state.bookingDetailsAll = action.payload;
    },
    setSelectedCardId: (state, action: PayloadAction<string>) => {
      state.selectedCardId = action.payload;
    },
    clearUserDetails: (state) => {
      state.userDetails = initialState.userDetails;
    },

    updateStepperValidation: (state, action) => {
      state.stepperValidated = {
        ...state.stepperValidated,
        [action.payload.key]: action.payload.value,
      };
    },

    updateStepperValidationToTrue: (state) => {
      state.stepperValidated = {
        details: true,
        price: true,
        usability: true,
        food: true,
        tableSetup: true,
        addOns: true,
      };
    },

    updateBookingDetails: (state, action) => {
      state.bookingdetails = {
        ...state.bookingdetails,
        ...action.payload,
      };
    },

    clearBookingDetails: (state) => {
      state.bookingdetails = initialState.bookingdetails;
      state.stepperValidated = initialState.stepperValidated;
    },
    clearBookingId: (state) => {
      state.bookingId = "";
    },
    clearAll: () => {
      return initialState;
    },
    applyCouponCodeDetails: (state, action) => {
      state.bookingdetails.couponDetails = action.payload;
    },
    setShowCapacityNotification: (state, action) => {
      state.showCapacityNotification = action.payload;
    },
  },
});

// Export actions and reducer
export const {
  handleCurrentStep,
  setUserDetails,
  setBookingId,
  setPackageId,
  setBookingDetails,
  setSelectedCardId,
  clearUserDetails,
  clearBookingDetails,
  clearAll,
  clearBookingId,
  setShowCapacityNotification,
  updateBookingDetails,
  setCurrentSelectedVenueToBook,
  applyCouponCodeDetails,
  updateStepperValidation,
  updateStepperValidationToTrue,
} = VenueBookingSlice.actions;

export default VenueBookingSlice.reducer;
